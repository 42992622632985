export default {
    language: {
        name: '繁體中文'
    },
    sidebar: {
        home: '數據概覽',
        endbenutzer: '用戶管理',
        plant: '場景管理',
        plant_plant: '電廠管理',
        device: '設備管理',
        datalog: '採集器列表',
        inverter: '逆變器列表',
        sotrage: '儲能列表',
        socket: "插座列表",
        battery: '電池列表',
        agent: '代理商管理',
        systematicSupply: '出貨日誌',
        firmware: '固件列表',
        firmware_up: '固件上傳',
        firmware_log: '升級記錄',
        system: '系統設置',
        system_datalog_log: '採集器日誌',
        system_log: '操作日誌',
        system_tongxun: '通訊日誌',
        system_app: 'App 版本',
        system_limits:'權限管理',
        system_menu:'選單列',
        system_company:'公司管理',

        personal: '用戶資訊',
        logout: '退出帳戶',

        charger: "充電樁列表",
mater: '電表'
    },
    login: {
        email: "郵箱",
        password: "密碼",
        remember: "記住郵箱和密碼",
        enteremail: "請輸入郵箱",
        enterpassword: "請輸入密碼",
        iot: "IoT漏洞批漏",
        Cookies: "我們的網址由我們以及部分瀏覽器提供cookies。Cookies是網站運營所必要的，繼續使用表示您同意使用cookies。",
        agree:"同意",
        refuse:"拒絕",



    },
    home: {
        echarts1: {
            title: '終端用戶數據概覽',
            subhead: `今日新增/用戶總數`,
            title: "註冊用戶",
        },
        echarts2: {
            title: '終端用戶線上概覽',
            subhead: `今日登錄/用戶總數`,
            title: "登錄用戶",
        },
        echarts3: {
            title: '代理商數據概覽',
            subhead: `今日登錄/代理商總數`,
            title: "添加代理商",
        },
        echarts4: {
            title: '場景數據概覽',
            subhead: '新增場景/場景總數',
            title: '添加場景',
            title_plant: "電站數據概覽",
subhead_plant: "新增電站/電站總數",
title_plant: "添加電站",
        },
        echarts5: {
            title: '採集器數據概覽',
            subhead: `新增採集器/採集器總數`,
            title: "添加採集器",
        },
        echarts6: {
            title: '設備數據概覽',
            subhead: `新增設備/設備總數`,
            title: "添加設備",
        },
    },
    End_user: {
        input_email: "請輸入用戶郵箱",
        btn_search: "搜索",
        btn_add: "添加",
        btn_delete: "刪除",
        btn_binding: "綁定",

        add_station: "新增場景",

        lable_StationName: "場景名稱",
        lable_StationType: "場景類型",
        lable_PVCapacity: "太陽電池容量",
        lable_InstalltionDate: "安裝日期",
        lable_Country: "國家",
        lable_City: "城市",
        lable_Address: "地址",
        lable_TimeZone: "時區",
        lable_Energyprice: "能源單價",
        lable_IncomeUnit: "收入單位",

        input_StationName: "請輸入場景名稱",
        input_StationType: "請選擇類型",
        input_PVCapacity: "請輸入電池容量",
        input_InstalltionDate: "請選擇安裝日期",
        input_Country: "請選擇國家",
        input_City: "請選擇城市",
        input_Address: "請輸入地址",
        input_TimeZone: "請選擇時區",
        input_Energyprice: "請輸入單價",
        input_IncomeUnit: "選擇收入單位",

        max_20: "字元最大不能超過20個",
        max_5: "字元最大不能超過5個",
        max_8: "字元最大不能超過8個",
        format: "請輸入正確的格式",

        close: "關閉",
        submit: "提交",

        title_delete: "刪除？",
        no: "否",
        yes: "是",

        Bingding_Agent: "綁定代理商",
        Agent_Code: "代理商:",
        please_select: "請選擇",
    },
    plant: {
        input_email: "請輸入用戶郵箱",


        search: "搜索",
        edit: "編輯",
        delete: "刪除",

        edit_plant: "編輯場景",
        input_station: "請輸入場景名稱",
        lable_StationName: "場景名稱",
        lable_StationType: "場景類型",

        lable_PVCapacity: "太陽電池容量",
        lable_InstalltionDate: "安裝日期",
        lable_Country: "國家",
        lable_City: "城市",
        lable_Address: "地址",
        lable_TimeZone: "時區",
        lable_Energyprice: "能源單價",
        lable_IncomeUnit: "收入單位",

        input_StationName: "請輸入場景名稱",
        input_StationType: "請選擇類型",
        input_PVCapacity: "請輸入電池容量",
        input_InstalltionDate: "請選擇安裝日期",
        input_Country: "請選擇國家",
        input_City: "請選擇城市",
        input_Address: "請輸入地址",
        input_TimeZone: "請選擇時區",
        input_Energyprice: "請輸入單價",
        input_IncomeUnit: "選擇收入單位",

        max_20: "字元最大不能超過20個",
        max_5: "字元最大不能超過5個",
        max_8: "字元最大不能超過8個",
        format: "請輸入正確的格式",

        close: "關閉",
        submit: "提交",

        title_delete: "刪除？",
        no: "否",
        yes: "是",

    },
    datalog: {
        email: "請輸入郵箱",
        datalogSn: "請輸入採集器序列號",
        search: "搜索",
        Version: "版本號",
        setting: "設置",
        upgrade: "升級",
        delete: "刪除",

        title_datalog: "採集器設置",

        span_info: "資訊",
        span_datalog: "採集器序列號:",
        span_name: "別名:",
        span_time: "更新時間:",
        span_firmware: "信號/固件版本:",

        span_order: "命令",
        span_settingdns: "設置域名",
        span_inputsetting: "請輸入域名",
        span_upgrade: "升級採集器",
        span_reset: "重啟採集器",

        span_setup: "高級設置",
        span_param: "參數",
        span_value: "值",

        btn_setup: "高級設置",
        btn_setting: "設置",
        btn_settingdns: "設置域名",
        btn_upgrade: "升級",
        btn_reset: "重啟",
        btn_read: "讀取",
        btn_close: "取消",

        methods_version: "檢測版本更新?",
        no: "否",
        yes: "是",

        methods_delete: "刪除?",
        methods_rereset: "重啟?",
        methodes_inputDns: "請輸入域名",
        methodes_DNS: "是否設置此域名",
    },
    sotrage: {
        select: "請選擇",
        email: "請輸入郵箱",
        datalogSn: "請輸入採集器序列號",
        deviceSn: "請輸入設備序列號",
        search: "搜索",

        sotrage: "儲能設置",
        Function_enable: "功能啟用",
Model_number: "機型編號",
output_power: "輸出功率",
individual_address: "單個地址",
Set_number: "設置序列號",
Set_time: "設置時間",
Set_Multiple_addresses:"多個地址",


    },
    device: {
        select: "請選擇",
        email: "請輸入郵箱",
        datalogSn: "請輸入採集器序列號",
        deviceSn: "請輸入設備序列號",
        search: "搜索",

        title: "逆變器設置",
        setting: "設置",
        setting_inverte: "設置逆變器參數",
        read_data_device:"讀取逆變器參數:",

        input_start_address: "起始寄存器地址",
        input_ent_address: "終止寄存器地址",
        input_setting_value: "請輸入設置值",

        read_data: "讀取寄存器數據:",
        input_start_read: "起始寄存器地址",
        input_end_read: "終止寄存器地址",

        btn_address: "設置地址",
        btn_value: "設置參數",
        btn_read: "讀取",
        btn_close: "返回",

        selet_data: "選擇日期",
    },
    battery: {
        select: "請選擇",
        email: "請輸入郵箱",
        datalogSn: "請輸入採集器序列號",
        deviceSn: "請輸入設備序列號",
        search: "搜索"

    },
    socket: {
        title: "插座設置",
        setting: "設置",
        span: "設置了多個寄存器地址，值也要設置多個。 設置值中間要用 , 隔開，示例（1，2，3）",
        setting_device: "設置逆變器參數：",
        startaddress: "起始寄存器地址",
        stopaddress: "終止寄存器地址",
        value: "請輸入設置值",
        readdata: "讀取寄存器數據：",
        setting_address: "設置地址",
        setting_value: "設置參數",
        read: "讀取",
        return: "返回",
    },
    agent: {
        close: "取消",
        submit: "提交",
        input_email: "請輸入用戶郵箱",
        search: "搜索",
        add_agent: "添加代理商",
        import_device: "導入設備",
        edit: "編輯",

        add_agent: "添加代理商",
        title: "添加代理商",
        email: "郵箱",
        password: "密碼",
        phone: "手機號碼",
        company: "公司",
        language: "語言",
        address: "地址",

        input_email: "請輸入郵箱",
        input_password: "請輸入密碼",
        input_phone: "請輸入手機號",
        input_company: "請選擇公司",
        input_language: "請輸入語言",
        input_address: "請輸入地址",

        relus_email: "請輸入有效的電子郵件地址",

        title_edit: "編輯代理商",

        title_device: "上傳",
        span: "示例: Excle 表格",
        btn_upload: "上傳",

        delete: "刪除",
        agent_delete: "刪除代理商?",
    },
    ship: {
        starttime: "開始時間",
        endtime: "結束時間",
        deviceSn: "請輸入設備序列號",
        select: "請選擇公司",
        search: "搜索"

    },



    Firmware: {
        file_name: "請輸入檔案名字",
        search: "搜索",
        add: "添加",
        delete: "刪除",
        title: "固件上傳",
        select_folder: "選擇資料夾:",
        select_folder_type: "請選擇資料夾類型",
        craete_folder: "建立一個新的資料夾",
        folder_path: "資料夾路徑：",
        folder_name: "文件名稱:",
        input_folder_name: "輸入資料夾名稱",
        create: "建立資料夾",
        listed_files: "文件列表：",
        delete: "刪除",


        import_folder: "導入文件",
        uplad_folder: "上傳文件",
        close: "返回",
    },
    Upgrade: {
        starttime: "開始時間",
        endtime: "結束時間",
        deviceSn: "請輸入設備序列號",
        type: "請選擇類型",
        search: "搜索",

        datalog: "採集器",
        Inverter: "逆變器",
        Control: "控制器",

    },
    datalogs_logs: {
        starttime: "開始時間",
        endtime: "結束時間",
        deviceSn: "請輸入設備序列號",
        Version: "請輸入版本",
        search: "搜索"
    },
    Logs: {
        starttime: "開始時間",
        endtime: "結束時間",
        deviceSn: "請輸入設備序列號",
        type: "請選擇類型",
        search: "搜索",


    },
    Special: {
        starttime: "開始時間",
        endtime: "結束時間",

        deviceSn: "請輸入設備序列號",
        datalogSn: "請輸入採集器序號",
        type: "請選擇類型",
        search: "搜索",
        save: "保存",
        Examples: "範例：(2305210230,2305210139)",
    },
    App: {
        System_Config: "系统配置",
        APP_Version: "App版本發佈",
        Android_Version: "安卓版本:",
        IOS_Version: "IOS版本:",
        APP_Type: "App類型:",
        New_Version: "新版本",
        update_content: "更新內容描述:(用“/n”來劃分)",
        Whether_update: "是否強制更新：",
        no: "否",
        yes: "是",
        submit: "提交",
        projectType:"項目類型",
        VersionType:"版本類型",
    },
    Tips: {
        app_submit: "确定修改信息?",
        firmware_delete: "確認刪除?",
        firmware_deleteCDN: "刪除CDN中的文件?",
        yes: "是",
        no: "否",
    },

    global: {
        DataDetails: "資料詳情",
        device: "逆變器設置",
        device_data: "設置逆變器參數:",
        read_data:"讀取逆變器參數",
        storage: "儲能設置",
        storage_data: "設置儲能參數",
        storage_read:"讀取儲能參數",

        battery: "電池設置",
        battery_data: "設置電池參數",

        socket: "插座設置",
        socket_data: "設置插座參數",
        Read_Write:"讀/寫數據",
        
        select:"請選擇",
        value:"值",
        password:"密碼設置",
        enter:"輸入密碼",
        number:"序列號",
        enternumber:"輸入序列號或選擇讀取序列號",
        resetdatalog:"重啟採集器",

        reset:"重啟",

        read:"讀取",
        setting:"設置",
        read_battery_data:"讀取電池數據",

        bms_sanke_Li: {  
            time: "BMS时间",  
            serialNumber: "BMS序列号",  
            batterySerialNumber: "电池序列号",  
            chargeDischargeLimits: "充放电限制",  
            shieldProtection: "屏蔽保护",  
            thresholdCurrent: "门限电流",  
            cycleCount: "循环次数",  
            modifyProtocol: "修改协议",  
            overallOvervoltageAlarm: "总体过压告警",  
            overallOvervoltageProtection: "总体过压保护",  
            overallOvervoltageProtectionRecover: "总体过压保护恢复",  
            overallOvervoltageProtectionDelay: "总体过压保护延时",  
            singleCellOvervoltageAlarm: "单体过压告警",  
            singleCellOvervoltageProtection: "单体过压保护",  
            singleCellOvervoltageProtectionRecover: "单体过压保护恢复",  
            singleCellOvervoltageProtectionDelay: "单体过压保护延时",  
            overallOverdischargeAlarm: "总体过放告警",  
            overallOverdischargeProtection: "总体过放保护",  
            overallOverdischargeProtectionRecover: "总体过放保护恢复",  
            overallOverdischargeProtectionDelay: "总体过放保护延时",  
            singleCellOverdischargeAlarm: "单体过放告警",  
            singleCellOverdischargeProtection: "单体过放保护",  
            singleCellOverdischargeProtectionRecover: "单体过放保护恢复",  
            singleCellOverdischargeProtectionDelay: "单体过放保护延时",  
            chargeOvercurrentAlarm: "充电过流告警",  
            chargeOvercurrentProtection: "充电过流保护",  
            chargeOvercurrentProtectionDelay: "充电过流保护延时",  
            chargeOvercurrentSecondProtection: "充电过流二级保护",  
            chargeOvercurrentSecondProtectionDelay: "充电过流二级保护延时",  
            dischargeOvercurrentAlarm: "放电过流告警",  
            dischargeOvercurrentProtection: "放电过流保护",  
            dischargeOvercurrentProtectionDelay: "放电过流保护延时",  
            dischargeOvercurrentSecondProtection: "放电过流二级保护",  
            dischargeOvercurrentSecondProtectionDelay: "放电过流二级保护延时",  
            shortCircuitProtectionCurrent: "短路保护电流",  
            shortCircuitProtectionDelay: "短路保护延时",  
            highTemperatureChargingAlarm: "充电高温告警",  
            highTemperatureChargingProtection: "充电高温保护",  
            highTemperatureChargingProtectionRecover: "充电过高保护恢复",  
            highTemperatureDischargingAlarm: "放电高温告警",  
            highTemperatureDischargingProtection: "放电高温保护",  
            highTemperatureDischargingProtectionRecover: "放电高温保护恢复",  
            lowTemperatureChargingAlarm: "充电低温告警",  
            lowTemperatureChargingProtection: "充电低温保护",  
            lowTemperatureChargingProtectionRecover: "充电过低保护恢复",  
            lowTemperatureDischargingAlarm: "放电低温告警",  
            lowTemperatureDischargingProtection: "放电低温保护",  
            lowTemperatureDischargingProtectionRecover: "放电低温保护恢复",  
            powerTubeHighTemperatureAlarm: "功率管高温告警",  
            powerTubeHighTemperatureProtection: "功率管高温保护",  
            powerTubeHighTemperatureProtectionRecover: "功率管高温保护恢复",  
            environmentHighTemperatureAlarm: "环境高温告警",  
            environmentHighTemperatureProtection: "环境高温保护",  
            environmentHighTemperatureProtectionRecover: "环境高温保护恢复",  
            environmentLowTemperatureAlarm: "环境低温告警",  
            environmentLowTemperatureProtection: "环境低温保护",  
            environmentLowTemperatureProtectionRecover: "环境高低保护恢复",  
            balancingStartVoltage: "均衡开启电压",  
            balancingStartVoltageDifference: "均衡开启压差",  
            fullChargeVoltage: "充满判断电压",  
            fullChargeCurrent: "充满判断电流",  
            singleCellLowVoltageSleep: "单体低压休眠电压",  
            singleCellLowVoltageSleepDelay: "单体低压休眠延时",  
            standbySleepDelay: "待机休眠延时",  
            lowBatteryAlarmValue: "低电量告警值",  
            overchargeProtectionCapacityRatioRelease: "过充保护容量比解除",  
            heatingFilmStartTemperature: "加热膜开启温度",  
            heatingFilmStopTemperature: "加热膜关闭温度"  
          },
          TouchSetting:"透傳下發",

    },

    btn: {
        setting_address: "設置地址",
        close: "關閉",
        submit: "提交",
        Setting_parameters: "設置參數",
        Reading: "讀取",
        search: "搜索",
        add: "添加",
        import: "導入",
        download: "下載示例",
        edit: "編輯",
        login:"登錄",
        export:"匯出",
        agent:"代理商",
        menu:"菜單",

        limits:"權限分配",
        AddZ:"添加主菜单",

    },
    form: {
        addShip: "添加出貨設備",
        editShip: "編輯出貨設備",
        import: "導入設備資訊",


        shipSN: "出貨序列號",
        agent: "代理商",
        networkCall: "是否執行配網回調",
        warrantyPeriod: "質保時間",
        shiptime: "出貨時間",
        shiptype: "出貨類型",
        classify: "分類",
        file: "將檔案拖放到此處或點擊上傳",

        edit_plant: "編輯場景",
        input_station: "請輸入場景名稱",
        lable_StationName: "場景名稱",
        lable_StationType: "場景類型",
        input_StationName: "請輸入場景名稱",

        edit_plant_plant: "編輯電廠",
input_station_plant: "請輸入電廠名稱",
lable_StationName_plant: "電廠名稱",
lable_StationType_plant: "電廠類型",
input_StationName_plant: "請輸入電廠名稱"
    },
    placeholder: {
        PshipSN: "輸入出貨序列號",
        Pagent: "輸入代理商編碼",
        PnetworkCall: "確認是否回調",
        PwarrantyPeriod: "確認質保時間",
        Pshiptime: "確認出貨時間",
        Pshiptype: "確認出貨類型",
        Pshiptype2: "確認出貨類型",

        Pclassify: "確認分類",
    },
    limits:{
        AddRoles: "添加角色",
nametip: "請輸入角色名字",
remark: "請輸入備註",
EditRoles:"編輯角色",
RolesAgent:"角色綁定的代理商",
RoleName:"角色名字:",
Remark:"备注",
    },
    Menu:{
        AddC: "添加菜單",
        EditC:"修改菜單",

MenuName: "菜單名字",
MenuNameTip: "請輸入菜單名稱",
url: "地址",
urlTip: "請輸入地址",
MenuType: "菜單類型",
MenuTypeTip: "請選擇菜單類型",
Remark: "備註",
RemarkTip: "請輸入備註資訊",


    } ,info:{
        Basic_Information: "基本資訊",
User_name: "用戶名稱",
Company: "公司",
Adress: "地址",
Phone: "手機號碼",
Change_Password: "修改密碼",
Old_Password:"旧密码",

New_Password: "新密碼",
Confirm_Password: "確認密碼",
Update_Acknowledge: "確認修改",
tip1: "請輸入密碼",
tip2: "請再次輸入密碼",
tip3: "兩次輸入密碼不一致！",
tip4: "操作不可逆請確認您的資訊",
    },
}