export default {
    language: {
        name: 'Italiano'
    },
    sidebar: {
        home: 'Panoramica dei dati',
        endbenutzer: 'Gestione terminale',
        plant: 'Scene',
        plant_plant: 'Centrale',
        device: 'Gest. dispositivi',
        datalog: 'Lista collezionisti',
        inverter: 'Lista inverter',
        sotrage: 'Energia stoccata',
        socket: "Presa",
        battery: 'Elenco delle pile',
        agent: 'Gestione agenti',
        systematicSupply: 'Giornale di bordo',
        firmware: 'Elenco fw',
        firmware_up: 'sull’impresa',
        firmware_log: 'Reg. aggiornamento',
        system: 'Impost sistema',
        system_datalog_log: 'Reg. raccoglitore',
        system_log: 'Reg. operazioni',
        system_tongxun: 'Reg. comunicazioni',
        system_app: 'Ver. app',
        system_limits:'GdP',
        system_menu:'Barra dei menu',
        system_company:'Gestione aziendale',

        personal: 'Informazioni sull utente',
        logout: 'Conto di uscita',
        charger: "Elenco delle colonnine di ricarica",
mater: 'Contatore elettrico',
    },
    login: {
        email: "email",
        password: "password",
        remember: "ricorda email e password",
        enteremail: "inserisci l'email",
        enterpassword: "inserisci la password",
        iot: "Rivelazione delle vulnerabilità IoT",
        Cookies: "Il nostro sito web utilizza cookies forniti da noi e da alcuni browser. I cookies sono necessari per il funzionamento del sito web. Continuando a utilizzare il sito, accetti l'utilizzo dei cookies.",
        agree:"accettare",
        refuse:"Rifiutare",

    },
    home: {
        echarts1: {
            title: 'Panoramica dei dati degli utenti finali',
            subhead: "Nuovi utenti oggi/Numero totale di utenti",
            title: "Utenti registrati",
        },
        echarts2: {
            title: 'Panoramica degli utenti finali online',
            subhead: "Accessi odierni/Numero totale di utenti",
            title: "Utenti connessi",
        },
        echarts3: {
            title: 'Panoramica dei dati dei rivenditori',
            subhead: "Accessi odierni/Numero totale di rivenditori",
            title: "Aggiungi rivenditore",
        },
        echarts4: {
            title: 'Panoramica dei dati della scena',
            subhead: 'Nuove scene/Totale delle scene',
            title: 'Aggiungi scena',
            title_plant: "Panoramica dei dati dell'impianto",
subhead_plant: "Nuove installazioni/Installazioni totali",
title_plant: "Aggiungi impianto",
        },
        echarts5: {
            title: 'Panoramica dei dati dei collezionisti',
            subhead: "Nuovi collezionisti/Numero totale di collezionisti",
            title: "Aggiungi collezionista",
        },
        echarts6: {
            title: 'Panoramica dei dati dei dispositivi',
            subhead: "Nuovi dispositivi/Numero totale di dispositivi",
            title: "Aggiungi dispositivo",
        }
    },
    End_user: {
        input_email: "Inserisci l'email dell'utente",
        btn_search: "Cerca",
        btn_add: "Aggiungi",
        btn_delete: "Elimina",
        btn_binding: "Associa",

        add_station: "Aggiungi scenario",

        lable_StationName: "Nome della scena",
        lable_StationType: "Tipo di scena",
        lable_PVCapacity: "Capacità pannelli solari",
        lable_InstalltionDate: "Data di installazione",
        lable_Country: "Paese",
        lable_City: "Città",
        lable_Address: "Indirizzo",
        lable_TimeZone: "Fuso orario",
        lable_Energyprice: "Prezzo dell'energia",
        lable_IncomeUnit: "Unità di reddito",

        input_StationName: "Inserisci il nome della scena",
        input_StationType: "Seleziona il tipo",
        input_PVCapacity: "Inserisci la capacità dei pannelli solari",
        input_InstalltionDate: "Seleziona la data di installazione",
        input_Country: "Seleziona il paese",
        input_City: "Seleziona la città",
        input_Address: "Inserisci l'indirizzo",
        input_TimeZone: "Seleziona il fuso orario",
        input_Energyprice: "Inserisci il prezzo",
        input_IncomeUnit: "Seleziona l'unità di reddito",

        max_20: "Massimo 20 caratteri",
        max_5: "Massimo 5 caratteri",
        max_8: "Massimo 8 caratteri",
        format: "Inserisci un formato corretto",

        close: "Chiudi",
        submit: "Invia",

        title_delete: "Eliminare?",
        no: "No",
        yes: "Sì",

        Bingding_Agent: "Associa agente",
        Agent_Code: "Agente:",
        please_select: "Seleziona",
    },
    plant: {
        input_email: "Inserisci l'email dell'utente",

        search: "Cerca",
        edit: "Modifica",
        delete: "Elimina",

        edit_plant: "Modifica scenario",
        input_station: "Inserisci il nome dello scenario",
        lable_StationName: "Nome dello scenario",
        lable_StationType: "Tipo di scenario",

        lable_PVCapacity: "Capacità pannelli solari",
        lable_InstalltionDate: "Data di installazione",
        lable_Country: "Paese",
        lable_City: "Città",
        lable_Address: "Indirizzo",
        lable_TimeZone: "Fuso orario",
        lable_Energyprice: "Prezzo dell'energia",
        lable_IncomeUnit: "Unità di reddito",

        input_StationName: "Nome dello scenario",
        input_StationType: "Seleziona il tipo",
        input_PVCapacity: "Inserisci la capacità dei pannelli solari",
        input_InstalltionDate: "Seleziona la data di installazione",
        input_Country: "Seleziona il paese",
        input_City: "Seleziona la città",
        input_Address: "Inserisci l'indirizzo",
        input_TimeZone: "Seleziona il fuso orario",
        input_Energyprice: "Inserisci il prezzo",
        input_IncomeUnit: "Seleziona l'unità di reddito",

        max_20: "Massimo 20 caratteri",
        max_5: "Massimo 5 caratteri",
        max_8: "Massimo 8 caratteri",
        format: "Inserisci un formato corretto",

        close: "Chiudi",
        submit: "Invia",

        title_delete: "Eliminare?",
        no: "No",
        yes: "Sì",

    },
    datalog: {
        email: "Inserisci l'email",
        datalogSn: "numero di serie del raccoglitore",
        search: "Cerca",
        Version: "Versione",
        setting: "Impostazioni",
        upgrade: "Aggiorna",
        delete: "Elimina",

        title_datalog: "Impostazioni del collezionista",

        span_info: "Informazioni",
        span_datalog: "Numero di serie del collezionista:",
        span_name: "Alias:",
        span_time: "Ultimo aggiornamento:",
        span_firmware: "Segnale/Versione firmware:",

        span_order: "Comandi",
        span_settingdns: "Imposta nome di dominio",
        span_inputsetting: "Inserisci nome di dominio",
        span_upgrade: "Aggiorna collezionista",
        span_reset: "Riavvia collezionista",

        span_setup: "Impostazioni avanzate",
        span_param: "Parametro",
        span_value: "Valore",

        btn_setup: "Impostazioni avanzate",
        btn_setting: "Impostazioni",
        btn_settingdns: "Imposta nome di dominio",
        btn_upgrade: "Aggiorna",
        btn_reset: "Riavvia",
        btn_read: "Leggi",
        btn_close: "Annulla",

        methods_version: "Verificare l'aggiornamento della versione?",
        no: "No",
        yes: "Sì",

        methods_delete: "Eliminare?",
        methods_rereset: "Riavviare?",
        methodes_inputDns: "Inserisci il nome di dominio",
        methodes_DNS: "Impostare questo nome di dominio?",
    },
    sotrage: {
        select: "Seleziona",
        email: "Inserisci l'email",
        datalogSn: "numero di serie del raccoglitore",
        deviceSn: "Num. di serie disp.",
        search: "Cerca",

        storage: "Impostazioni di stoccaggio",
        Function_enable: "Abilitazione funzione",
        Model_number: "Numero di modello",
        output_power: "Potenza di uscita",
        individual_address: "Indirizzo singolo",
        Set_number: "Numero di impostazione",
        Set_time: "Ora di impostazione",
        Set_Multiple_addresses:"più indirizzi",

    },
    device: {
        select: "Seleziona",
        email: "Inserisci l'email",
        datalogSn: "numero di serie del raccoglitore",
        deviceSn: "Num. di serie disp.",
        search: "Cerca",

        title: "Impostazioni dell'inverter",
        setting: "Impostazioni",
        setting_inverte: "Imposta i parametri dell'inverter",
        read_data_device:"Lettura dei parametri dell'inverter",
        input_start_address: "Indirizzo del registro iniziale",
        input_end_address: "Indirizzo del registro finale",
        input_setting_value: "Inserisci il valore da impostare",

        read_data: "Leggi i dati del registro:",
        input_start_read: "Indirizzo del registro iniziale",
        input_end_read: "Indirizzo del registro finale",

        btn_address: "Imposta indirizzo",
        btn_value: "Imposta parametro",
        btn_read: "Leggi",
        btn_close: "Indietro",

        select_data: "Seleziona data",
    },
    battery: {
        select: "Seleziona",
        email: "Inserisci l'email",
        datalogSn: "numero di serie del raccoglitore",
        deviceSn: "Num. di serie disp.",
        search: "Cerca"

    },
    socket: {
        title: "Impostazioni della presa elettrica",
        setting: "Impostazioni",
        span: "Se hai impostato più indirizzi di registro, devi anche impostare più valori. I valori devono essere separati da virgola (esempio: 1, 2, 3)",
        setting_device: "Imposta i parametri della presa elettrica:",
        startaddress: "Indirizzo di registro iniziale",
        stopaddress: "Indirizzo di registro finale",
        value: "Inserisci il valore da impostare",
        readdata: "Leggi i dati del registro:",
        setting_address: "Imposta indirizzo",
        setting_value: "Imposta parametro",
        read: "Leggi",
        return: "Indietro",
    },
    agent: {
        close: "Chiudi",
        submit: "Invia",
        input_email: "Inserisci l'email dell'utente",
        search: "Cerca",
        add_agent: "Aggiungi agente",
        import_device: "Importa dispositivo",
        edit: "Modifica",

        add_agent: "Aggiungi agente",
        title: "Aggiungi agente",
        email: "Email",
        password: "Password",
        phone: "Numero di telefono",
        company: "Azienda",
        language: "Lingua",
        address: "Indirizzo",

        input_email: "Inserisci l'email",
        input_password: "Inserisci la password",
        input_phone: "Inserisci il numero di telefono",
        input_company: "Seleziona l'azienda",
        input_language: "Inserisci la lingua",
        input_address: "Inserisci l'indirizzo",

        relus_email: "Inserisci un indirizzo email valido",

        title_edit: "Modifica agente",

        title_device: "Carica",
        span: "Esempio: foglio Excel",
        btn_upload: "Carica",

        delete: "Elimina",
        agent_delete: "Eliminare l'agente?",
    },
    ship: {
        starttime: "Ora di inizio",
        endtime: "Ora di fine",
        deviceSn: "Num. di serie disp.",
        select: "Seleziona azienda",
        search: "Cerca"

    },



    Firmware: {
        file_name: "Inserisci il nome del file",
        search: "Cerca",
        add: "Aggiungi",
        delete: "Elimina",
        title: "Caricamento del firmware",
        select_folder: "Seleziona una cartella:",
        select_folder_type: "Seleziona il tipo di cartella",
        craete_folder: "Crea una nuova cartella",
        folder_path: "Percorso della cartella:",
        folder_name: "Nome della cartella:",
        input_folder_name: "Inserisci il nome della cartella",
        create: "Crea cartella",
        listed_files: "Elenco dei file:",
        delete: "Elimina",

        import_folder: "Importa cartella",
        uplad_folder: "Carica cartella",
        close: "Indietro",
    },



    Upgrade: {
        starttime: "Orario di inizio",
        endtime: "Orario di fine",
        deviceSn: "Num. di serie disp.",
        type: "Seleziona il tipo",
        search: "Cerca",

        datalog: "Collezionista",
        Inverter: "Inverter",
        Control: "Controllore",

    },
    datalogs_logs: {
        starttime: "Orario di inizio",
        endtime: "Orario di fine",
        deviceSn: "Num. di serie disp.",
        Version: "Inserisci la versione",
        search: "Cerca"
    },
    Logs: {
        starttime: "Orario di inizio",
        endtime: "Orario di fine",
        deviceSn: "Num. di serie disp.",
        type: "Seleziona il tipo",
        search: "Cerca",


    },
    Special: {
        starttime: "Ora di inizio",
        endtime: "Ora di fine",

        deviceSn: "Num. di serie disp.",
        datalogSn: "numero di serie del raccoglitore",
        type: "Seleziona il tipo",
        search: "Cerca",
        save: "Salva",
        Examples: "Esempi: (2305210230, 2305210139)",
    },
    App: {
        System_Config: "Configurazione di sistema",
        APP_Version: "Pubblicazione della versione dell'app",
        Android_Version: "Versione Android:",
        IOS_Version: "Versione iOS:",
        APP_Type: "Tipo di app:",
        New_Version: "Nuova versione",
        update_content: "Descrizione degli aggiornamenti: (utilizza '/n' per separare)",
        Whether_update: "Aggiornamento obbligatorio:",
        no: "No",
        yes: "Sì",
        submit: "Invia",
        projectType:"Type de projett",
        VersionType:"Type de version",
    },
    Tips: {
        app_submit: "Confermi di voler modificare le informazioni?",
        firmware_delete: "Conferma eliminazione?",
        firmware_deleteCDN: "Elimina file nel CDN?",
        yes: "Sì",
        no: "No",
    },

    global: {
        DataDetails: "Dettagli dei dati",
        device: "Impostazioni dell'inverter",
        device_data: "Imposta i parametri dell'inverter",
        read_data:"Lettura dei parametri dell'inverter",
        storage: "Impostazioni di storage",
        storage_data: "Imposta i parametri di storage",
        storage_read:"Lettura dei parametri di accumulo di energia",

        battery: "Impostazioni della batteria",
        battery_data: "Imposta i parametri della batteria",

        socket: "Impostazioni della presa",
        socket_data: "Imposta i parametri della presa",
        Read_Write:"Lettura/Scrittura dei dati",
        select:"Selezionare",
        value:"Valore",
        password:"Impostazioni password",
        enter:"Inserisci password",
        number:"Numero di serie",
        enternumber:"Inserisci il numero di serie o seleziona per leggere il numero di serie",
        resetdatalog:"Riavviare il collettore",

        reset:"Riavvia",

        read:"Leggere",
        setting:"Impostazione",
        read_battery_data:"Leggere dati della batteria",

        bms_sanke_Li: {    
            time: "BMS Time",    
            serialNumber: "BMS Serial Number",    
            batterySerialNumber: "Battery Serial Number",    
            chargeDischargeLimits: "Charge and Discharge Limits",    
            shieldProtection: "Shield Protection",    
            thresholdCurrent: "Threshold Current",    
            cycleCount: "Cycle Count",    
            modifyProtocol: "Modify Protocol",    
            overallOvervoltageAlarm: "Overall Overvoltage Alarm",    
            overallOvervoltageProtection: "Overall Overvoltage Protection",    
            overallOvervoltageProtectionRecover: "Overall Overvoltage Protection Recovery",    
            overallOvervoltageProtectionDelay: "Overall Overvoltage Protection Delay",    
            singleCellOvervoltageAlarm: "Single Cell Overvoltage Alarm",    
            singleCellOvervoltageProtection: "Single Cell Overvoltage Protection",    
            singleCellOvervoltageProtectionRecover: "Single Cell Overvoltage Protection Recovery",    
            singleCellOvervoltageProtectionDelay: "Single Cell Overvoltage Protection Delay",    
            overallOverdischargeAlarm: "Overall Overdischarge Alarm",    
            overallOverdischargeProtection: "Overall Overdischarge Protection",    
            overallOverdischargeProtectionRecover: "Overall Overdischarge Protection Recovery",    
            overallOverdischargeProtectionDelay: "Overall Overdischarge Protection Delay",    
            singleCellOverdischargeAlarm: "Single Cell Overdischarge Alarm",    
            singleCellOverdischargeProtection: "Single Cell Overdischarge Protection",    
            singleCellOverdischargeProtectionRecover: "Single Cell Overdischarge Protection Recovery",    
            singleCellOverdischargeProtectionDelay: "Single Cell Overdischarge Protection Delay",    
            chargeOvercurrentAlarm: "Charge Overcurrent Alarm",    
            chargeOvercurrentProtection: "Charge Overcurrent Protection",    
            chargeOvercurrentProtectionDelay: "Charge Overcurrent Protection Delay",    
            chargeOvercurrentSecondProtection: "Charge Overcurrent Second Protection",    
            chargeOvercurrentSecondProtectionDelay: "Charge Overcurrent Second Protection Delay",    
            dischargeOvercurrentAlarm: "Discharge Overcurrent Alarm",    
            dischargeOvercurrentProtection: "Discharge Overcurrent Protection",    
            dischargeOvercurrentProtectionDelay: "Discharge Overcurrent Protection Delay",    
            dischargeOvercurrentSecondProtection: "Discharge Overcurrent Second Protection",    
            dischargeOvercurrentSecondProtectionDelay: "Discharge Overcurrent Second Protection Delay",    
            shortCircuitProtectionCurrent: "Short Circuit Protection Current",    
            shortCircuitProtectionDelay: "Short Circuit Protection Delay",    
            highTemperatureChargingAlarm: "High Temperature Charging Alarm",    
            highTemperatureChargingProtection: "High Temperature Charging Protection",    
            highTemperatureChargingProtectionRecover: "High Temperature Charging Protection Recovery",    
            highTemperatureDischargingAlarm: "High Temperature Discharging Alarm",    
            highTemperatureDischargingProtection: "High Temperature Discharging Protection",    
            highTemperatureDischargingProtectionRecover: "High Temperature Discharging Protection Recovery",    
            lowTemperatureChargingAlarm: "Low Temperature Charging Alarm",    
            lowTemperatureChargingProtection: "Low Temperature Charging Protection",    
            lowTemperatureChargingProtectionRecover: "Low Temperature Charging Protection Recovery",    
            lowTemperatureDischargingAlarm: "Low Temperature Discharging Alarm",    
            lowTemperatureDischargingProtection: "Low Temperature Discharging Protection",    
            lowTemperatureDischargingProtectionRecover: "Low Temperature Discharging Protection Recovery",    
            powerTubeHighTemperatureAlarm: "Power Tube High Temperature Alarm",    
            powerTubeHighTemperatureProtection: "Power Tube High Temperature Protection",    
            powerTubeHighTemperatureProtectionRecover: "Power Tube High Temperature Protection Recovery",    
            environmentHighTemperatureAlarm: "Environment High Temperature Alarm",    
            environmentHighTemperatureProtection: "Environment High Temperature Protection",    
            environmentHighTemperatureProtectionRecover: "Environment High Temperature Protection Recovery",    
            environmentLowTemperatureAlarm: "Environment Low Temperature Alarm",    
            environmentLowTemperatureProtection: "Environment Low Temperature Protection",    
            environmentLowTemperatureProtectionRecover: "Environment Low Temperature Protection Recovery",    
            balancingStartVoltage: "Balancing Start Voltage",    
            balancingStartVoltageDifference: "Balancing Start Voltage Difference",    
            fullChargeVoltage: "Full Charge Voltage",    
            fullChargeCurrent: "Full Charge Current",    
            singleCellLowVoltageSleep: "Single Cell Low Voltage Sleep Voltage",    
            singleCellLowVoltageSleepDelay: "Single Cell Low Voltage Sleep Delay",    
            standbySleepDelay: "Standby Sleep Delay",    
            lowBatteryAlarmValue: "Low Battery Alarm Value",    
            overchargeProtectionCapacityRatioRelease: "Overcharge Protection Capacity Ratio Release",    
            heatingFilmStartTemperature: "Heating Film Start Temperature",    
            heatingFilmStopTemperature: "Heating Film Stop Temperature"    
          },
          TouchSetting:"Invio di trasmissione trasparente",

    },

    btn: {
        setting_address: "Indirizzo impostazioni",
        close: "Chiudi",
        submit:"Invia",
        Setting_parameters: "Impostazione parametri",
        Reading: "Lettura",
        search: "Ricerca",

        add: "aggiungi",
        import: "importa",
        download: "scarica",
        edit: "modifica",
        login:"Accedi",
        export:"Esporta",
        agent:"Agente",
        menu:"Menu",

        limits:"Salvare l'assegnazione",
        AddZ:"Aggiungi menu principale",


    },
    form:{
        addShip: "Aggiungi dispositivo di spedizione",
        editShip: "Modifica dispositivo di spedizione",
        import: "Importa informazioni sul dispositivo",


shipSN: "Numero di serie di spedizione",
agent: "Agente",
networkCall: "Eseguire la chiamata di rete",
warrantyPeriod: "Periodo di garanzia",
shiptime: "Ora di spedizione",
shiptype: "Tipo di spedizione",
classify: "Classificazione",
file: "Trascina il file qui o clicca per caricare",


edit_plant: "Modifica scenario",
input_station: "Inserisci nome dello scenario",
lable_StationName: "Nome dello scenario",
lable_StationType: "Tipo di scenario",
input_StationName: "Inserisci nome dello scenario",

edit_plant_plant: "Mod. Centrale",
input_station_plant: "Inserisci nome centrale",
lable_StationName_plant: "Nome centrale",
lable_StationType_plant: "Tipo centrale",
input_StationName_plant: "Inserisci nome centrale",


    },
    placeholder:{
        PshipSN: "Inserisci il numero di serie di spedizione",
        Pagent: "Inserisci il codice agente",
        PnetworkCall: "Conferma la chiamata di rete",
        PwarrantyPeriod: "Conferma il periodo di garanzia",
        Pshiptime: "Conferma l'ora di spedizione",
        Pshiptype: "Conferma il tipo di spedizione",
        Pclassify: "Conferma la classificazione"
    },
    limits:{
        AddRoles: "Aggiungi ruoli",
nametip: "Inserisci il nome del ruolo",
remark: "Inserisci le note",
EditRoles:"Modifica ruolo",
RolesAgent:"Agente associato ai ruoli",
RoleName:"Nome ruolo:",
Remark:"Nota:",
    },
    Menu:{
        AddC: "Aggiungi Menu",
        EditC:"Modifica Menu",

MenuName: "Nome Menu",
MenuNameTip: "Inserisci il nome del menu",
url: "URL",
urlTip: "Inserisci l'URL",
MenuType: "Tipo Menu",
MenuTypeTip: "Seleziona il tipo di menu" ,
Remark: "Nota",
RemarkTip: "Inserisci informazioni sulla nota",


    } ,info:{
        Basic_Information: "Informazioni di base",
User_name: "Nome utente",
Company: "Azienda",
Adress: "Indirizzo",
Phone: "Numero di telefono",
Old_Password:"旧密码",

Change_Password: "Cambia password",
New_Password: "Nuova password",
Confirm_Password: "Conferma password",
Update_Acknowledge: "Conferma aggiornamento",
tip1: "Inserisci la password",
tip2: "Reinserisci la password",
tip3: "Le password non corrispondono!",
tip4: "L'operazione è irreversibile, conferma le tue informazioni",
    },
}