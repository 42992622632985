<template>
    <div id="app">

        <div class="login">

            <div class="login_bg">

                <div class="mask_layer" style="position: relative;">
                    <img v-if="dns_ym == 'server-hr.shuoxd.com'" src="../assets/img/NHlogoBG.png" style="margin-left:-386px; height: 500px;    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
                 border-radius:  5px 0px 0px 5px;">
                    <img v-else src="../assets/img/logobg.png" style="margin-left:-386px; height: 500px;    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
                 border-radius:  5px 0px 0px 5px;">


                    <div style="background-color: rgb(255, 255, 255); width: 600px; height: 500px; 
                    float: right; margin-right: -390px;  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2)">
                    </div>

                    <div class="login_box" style="position: absolute; top: 200px; left: 125%; transform: translateX(-50%);">

                        <img v-if="dns_ym == 'server-hr.shuoxd.com'" src="..//assets/img/NahuiLogo.png" alt="" class="logo"
                            style="position: absolute; top: -80px; left: 50%; transform: translateX(-50%); height: 50px;">
                        <img v-else src="..//assets/img/tenteklogin.png" alt="" class="logo"
                            style="position: absolute; top: -80px; left: 50%; transform: translateX(-50%); height: 50px;">


                        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm login_form">
                            <el-form-item prop="userName">
                                <el-input v-model.trim="ruleForm.userName" prefix-icon="el-icon-user-solid" autocomplete="off"
                                    :placeholder="$t('login.email')" class="input_size"></el-input>
                            </el-form-item>
                            <el-form-item prop="checkPass">
                                <el-input type="password" v-model.trim="ruleForm.checkPass" prefix-icon="el-icon-lock"
                                    show-password :placeholder="$t('login.password')" class="input_size" autocomplete="off"
                                    @keyup.enter.native="submitForm('ruleForm')"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-checkbox :label="$t('login.remember')" v-model.trim="ruleForm.remember" class="checkRemember"
                                    @keyup.enter.native="submitForm('ruleForm')"></el-checkbox>


                            </el-form-item>

                            <el-form-item>
                                <el-button type="primary" @click="submitForm('ruleForm');" class="input_btn" size="small"
                                    style="width: 300px;background-color: #FC8012 ;border: none;">{{ $t("btn.login") }}
                                </el-button>
                            </el-form-item>
                            <!-- <span style="cursor: pointer;color:rgb(173, 227, 241)" @click="Getiot()" class="iot-button" >{{$t('login.iot')}}</span>  -->
                            <div style="color:#1cb0dd">
                                <span :class="{ 'hover-gray': hover ,}" @mouseover="hover = true" @mouseleave="hover = false"   style="cursor: pointer;"
                                @click="Getiot()">{{ $t('login.iot') }}</span>
                            </div>
                           

                        </el-form>
                        <!-- <span style="position: absolute;width: 600px; bottom: -100px; left: 51%; transform: translateX(-50%); color: rgb(133, 130, 130);">2023~2028 © 深圳市天技新能源科技有限公司 版权所有粤ICP备19030955号</span> -->
                        <!-- <button @click="showModal = true">显示弹出层</button>   -->
                    </div>

                </div>
            </div>
            <div class="lang">
                <el-dropdown @command="changeLanguage">
                    <span class="el-dropdown-link"
                        style="margin-right: 20px;color: #0f0f0f;font-size: 20px;font-weight: 500;cursor: pointer;">
                        {{ $t('language.name') }}
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="中文">简体中文</el-dropdown-item>
                        <el-dropdown-item command="繁體">繁體中文</el-dropdown-item>
                        <el-dropdown-item command="English">English</el-dropdown-item>
                        <el-dropdown-item command="German">German</el-dropdown-item>
                        <el-dropdown-item command="Italiano">Italiano</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="modal" v-if="showModal" @click.self="showModal = false">
                <div class="modal-content">
                    <div>
                        <span>{{ $t('login.Cookies') }}</span>
                        <button @click="cookessave()"
                            style="float: right;padding: 5px 10px;cursor: pointer;margin-left: 10px;">{{ $t('login.agree') }}</button>
                            <button @click="cookessave()" style="float: right;padding: 5px 10px;cursor: pointer;">{{ $t('login.refuse') }}</button>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Cookies from 'js-cookie'
export default {
    data() {
        var checkName = (rule, value, callback) => {
            if (!value) {
                callback(new Error(this.$t('login.enteremail')));
            } else {
                callback();
            }
        };
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('login.enterpassword')));
            } else {
                callback();
            }
        };
        return {
            hover: false,
            // 假设这是你想要显示的文本  
            showModal: true,
            dns_ym: '',
            ruleForm: {
                userName: '',
                checkPass: '',
                remember: false,
            },
            rules: {
                userName: [
                    { validator: checkName, trigger: 'blur' }
                ],
                checkPass: [
                    { validator: validatePass, trigger: 'blur' }
                ]
            }
        }
    },


    mounted() {
        this.dns_ym = window.location.hostname;
        // console.log('当前域名:', this.dns_ym);
        this.ruleForm.userName = Cookies.get('account')
        this.ruleForm.checkPass = Cookies.get('password')
        if (Cookies.get('remember')) {
            this.ruleForm.remember = true;
        }


    },

    methods: {
        changeLanguage(command) {
            if (command === '中文') {
                this.$i18n.locale = 'zh-CN';
            } else if (command === 'English') {
                this.$i18n.locale = 'en-US';
            } else if (command === '繁體') {
                this.$i18n.locale = 'zh-HK';
            } else if (command === 'German') {
                this.$i18n.locale = 'de-DE';
            } else if (command === 'Italiano') {
                this.$i18n.locale = 'it-IT';
            }
            // console.log('11111111', command)
          
           // console.log('切换语言', this.$i18n.locale);
            localStorage.setItem('Accept-Language', this.$i18n.locale);
            sessionStorage.setItem('Accept-Language', this.$i18n.locale);
            this.$router.go(0);
        },
        cookessave() {
            this.showModal = false
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.login();
                } else {

                    return false;
                }
            });
        },
        Getiot() {
            this.$router.push({ name: 'iot' });

        },
        login() {
            const url = '/admin/agent/login';
            let params = {}
            let i = 0
            // console.log('Cookies-->', Cookies.get('remember'));
            if (this.ruleForm.remember == true && this.ruleForm.checkPass.length < 32) {
                params = { 'email': this.ruleForm.userName, 'password': this.$md5(this.ruleForm.checkPass) };
                // console.log('记住------------>', params);

            } else if (Cookies.get('remember') == true && this.ruleForm.checkPass.length != 32) {
                // console.log('记住2');
                params = { 'email': this.ruleForm.userName, 'password': this.ruleForm.checkPass };
                // console.log('记住2------------>', params);
            } else {
                params = { 'email': this.ruleForm.userName, 'password': this.$md5(this.ruleForm.checkPass) };
                // console.log('没记住');
                // console.log('没记住------------>', params);
            }
            this.$request.post(url, params).then(res => {
                this.$store.state.user = res.data.obj;
                // console.log('Response=>', res);
                if (res.data.result == 0) {
                    this.$store.state.user = res.data.obj;
                    // 第一次登录成功，密码进行加密
                    sessionStorage.setItem('userName', this.ruleForm.userName);
                    if (this.ruleForm.remember && i == 0) {
                        Cookies.set("account", this.ruleForm.userName)
                        Cookies.set("password", this.ruleForm.checkPass)
                        // Cookies.set("password",this.$md5(this.ruleForm.checkPass))  
                        sessionStorage.setItem('userType', res.data.obj.userType)
                        Cookies.set('remember', true)
                        // 第二次不加密，
                    } else if (this.ruleForm.remember) {
                        Cookies.set("account", this.ruleForm.userName)
                        Cookies.set("password", this.ruleForm.checkPass)
                        sessionStorage.setItem('userType', res.data.obj.userType)
                        Cookies.set('remember', true)

                    } else {
                        Cookies.set("account", '')
                        Cookies.set("password", '')
                        sessionStorage.setItem('userType', res.data.obj.userType)
                        Cookies.set('remember', '')
                    }
                    // console.log('公司父级iD', res.data.obj.deptId)
                    sessionStorage.setItem("deptId", res.data.obj.deptId)
                    sessionStorage.setItem("userInfo", res.data.obj.email)
                    sessionStorage.setItem("sysMenuIds", res.data.obj.sysMenuIds)
                    sessionStorage.setItem("userId", res.data.obj.id)
                    sessionStorage.setItem("address", res.data.obj.address)
                    sessionStorage.setItem("clientId", res.data.obj.clientId)
                    sessionStorage.setItem("company", res.data.obj.company)
                    sessionStorage.setItem("phone", res.data.obj.phone)
                    sessionStorage.setItem("createTime", res.data.obj.createTime)
                    sessionStorage.setItem("lastLoginTime", res.data.obj.lastLoginTime)
                    sessionStorage.getItem('Accept-Language', this.$i18n.locale)

                    if(res.data.obj.clientId==0){
                        this.$router.push({ path: this.redirect || "/home" });
                    }else{
                        this.$router.push({ path: this.redirect || "/enduser" });
                    }

                    this.$message({
                        type: 'success',
                        message: res.data.msg,
                        center: true
                    });
                } else {
                    this.$message({
                        type: 'info',
                        center: true,
                        message: res.data.msg,

                    });
                }
            }).catch((err) => {
                // console.log(err);
                // // console.log('Login failed');
            })
        },

    }
    ,
    watch: {
        'ruleForm.userName'(newValue, oldValue) {
            // // console.log('useName',newValue,oldValue);
            const that = this
            if (newValue = oldValue) {
                setTimeout(function () {
                    Cookies.set("account", '')
                    Cookies.set("password", '')
                    Cookies.set('remember', '')
                    that.ruleForm.checkPass = '';
                    that.ruleForm.remember = false;
                }, 100)

            }
        }

    }
}
</script>

<style lang="scss" scoped>
#app {

    width: 100%;
    height: 97.4vh;

    .login {
        width: 100%;
        height: 100%;
        display: flex;
        // justify-content: flex-end;

        .login_bg {
            width: 100%;
            height: 101.8%;
            background: url('@/assets/img/bgbg.png') no-repeat;
            background-size: cover;
            display: flex;
            justify-content: center;
            align-items: center;
            // position: relative;

            .mask_layer {


                .logo {
                    position: absolute;
                    // top: 40%;
                    // left: 50%;
                    // transform: translate(-40%, -50%);
                    // width: 250px;

                }

               

                .hover-gray {
                    color: gray;
                    /* 鼠标悬停时变为灰色 */
                }
            }

        }


    }

    .lang {
        position: fixed;
        /* 固定定位 */
        top: 10px;
        /* 底部对齐 */
        //   right: ; /* 左侧对齐 */  
        left: 95%;
        width: 100%;
        /* 宽度占满屏幕 */

        justify-content: center;
        /* 水平居中 */
        align-items: flex-end;
        /* 垂直底部对齐 */
        //   padding-bottom: 20px; /* 距离底部一些距离 */  
    }

    .modal {
        transition: opacity 0.5s ease;
        position: fixed;
        /* 固定定位 */
        bottom: 0;
        /* 底部对齐 */
        left: 0;
        /* 左侧对齐 */
        width: 100%;
        /* 宽度占满屏幕 */
        background-color: rgba(0, 0, 0, 0.5);
        /* 半透明背景 */
        z-index: 1000;
        /* 设置较高的 z-index 以确保覆盖其他内容 */
        display: flex;
        justify-content: center;
        /* 水平居中 */
        align-items: flex-end;
        /* 垂直底部对齐 */
        //   padding-bottom: 20px; /* 距离底部一些距离 */  
    }

    .modal-content {
        transition: opacity 0.5s ease;
        background-color: rgba(0, 0, 0, 0.5);
        margin: auto;
        padding: 10px;
        color: aliceblue;
        border: 1px solid #888;
        width: 100%;
        /* 宽度占可用空间的 80% */
        //   max-width: 600px; /* 最大宽度限制 */  
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        border-radius: 5px;
    }
}</style>
