export default{
    language: {
        name: '简体中文'
    },
    sidebar:{
        home:'数据概览',
        endbenutzer:'用户管理',
        plant:'场景管理',
        plant_plant:'电站管理',

        device:'设备管理',
        datalog:'采集器列表',
        inverter:'逆变器列表',
        sotrage:'储能列表',
        socket:"插座列表",
        battery:'电池列表',
        agent:'代理商管理',
        systematicSupply:'出货日志',
        firmware:'固件列表',
        firmware_up:'固件上传',
        firmware_log:'升级记录',
        system:'系统设置',
        system_datalog_log:'采集器日志',
        system_log:'操作日志',
        system_tongxun:'通讯日志',
        system_app:'App 版本',
        system_limits:'权限管理',
        system_menu:'菜单栏',
        system_company:'公司管理',
        personal:'用户信息',
        logout:'退出账户',

        charger:"充电桩列表",
        mater:'电表列表',


    },
    login:{
        email:"邮箱",
        password:"密码",
        remember:"记住邮箱和密码",
        enteremail:"请输入邮箱",     
        enterpassword:"请输入密码",
        iot:"iot漏洞批漏",
        Cookies:"我们的网址由我们以及部分浏览器提供 cookies。cookes 是网站运营所必要的，继续使用表示您同意使用cookies。",
        agree:"同意",
        refuse:"拒绝",

    },
    home:{
        echarts1:{
            title:'终端用户数据概览',
            subhead:`今日新增/用户总数`,
            title:"注册用户",
        },
        echarts2:{
            title:'终端用户在线概览',
            subhead:`今日登录/用户总数`,
            title:"登录用户",
        },
        echarts3:{
            title:'代理商数据概览',
            subhead:`今日登录/代理商总数`,
            title:"添加代理商",
        },
        echarts4:{
            title:'场景数据概览',
            subhead:`新增场景/场景总数`,
            title:"添加场景",

            title_plant:'电站数据概览',
            subhead_plant:`新增电站/电站总数`,
            title_plant:"添加电站",
        },
        echarts5:{
            title:'采集器数据概览',
            subhead:`新增采集器/采集器总数`,
            title:"添加采集器",
        },
        echarts6:{
            title:'设备数据概览',
            subhead:`新增设备/设备总数`,
            title:"添加设备",
        },
    },
    End_user:{
        input_email:"请输入用户邮箱",
        btn_search:"搜索",
        btn_add:"添加",
        btn_delete:"删除",
        btn_binding:"绑定",

        add_station:"添加场景",

        lable_StationName:"场景名字",
        lable_StationType:"场景类型",
        lable_PVCapacity:"太阳电池容量",
        lable_InstalltionDate:"安装日期",
        lable_Country:"国家",
        lable_City:"城市",
        lable_Address:"地址",
        lable_TimeZone:"时区",
        lable_Energyprice:"能源单价",
        lable_IncomeUnit:"收入单位",

        input_StationName:"请输入场景名字",
        input_StationType:"请选择类型",
        input_PVCapacity:"请输入电池容量",
        input_InstalltionDate:"请选择安装日期",
        input_Country:"请选择国家",
        input_City:"请选择城市",
        input_Address:"请输入地址",
        input_TimeZone:"请选择时区",
        input_Energyprice:"请输入单价",
        input_IncomeUnit:"选择收入单位",
        
        max_20:"字符最大不能超过20个",
        max_5:"字符最大不能超过5个",
        max_8:"字符最大不能超过8个",
        format:"请输入正确的格式",

        close:"关闭",
        submit:"提交",

        title_delete:"删除？",
        no:"否",
        yes:"是",

        Bingding_Agent:"绑定代理商",
        Agent_Code:"代理商:",
        please_select:"请选择",
    },
    plant:{
        input_email:"请输入用户邮箱",
       

        search:"搜索",
        edit:"编辑",
        delete:"删除",

        edit_plant:"编辑场景",
        input_station:"请输入场景名字",
        lable_StationName:"场景名字",
        lable_StationType:"场景类型",

        lable_PVCapacity:"太阳电池容量",
        lable_InstalltionDate:"安装日期",
        lable_Country:"国家",
        lable_City:"城市",
        lable_Address:"地址",
        lable_TimeZone:"时区",
        lable_Energyprice:"能源单价",
        lable_IncomeUnit:"收入单位",

        input_StationName:"请输入场景名字",
        input_StationType:"请选择类型",
        input_PVCapacity:"请输入电池容量",
        input_InstalltionDate:"请选择安装日期",
        input_Country:"请选择国家",
        input_City:"请选择城市",
        input_Address:"请输入地址",
        input_TimeZone:"请选择时区",
        input_Energyprice:"请输入单价",
        input_IncomeUnit:"选择收入单位",
        
        max_20:"字符最大不能超过20个",
        max_5:"字符最大不能超过5个",
        max_8:"字符最大不能超过8个",
        format:"请输入正确的格式",

        close:"关闭",
        submit:"提交",

        title_delete:"删除？",
        no:"否",
        yes:"是",

    },
    datalog:{
        email:"请输入邮箱",
        datalogSn:"请输入采集器序列号",
        search:"搜索",
        Version:"版本号",
        setting:"设置",
        upgrade:"升级",
        delete:"删除",

        title_datalog:"采集器设置",

        span_info:"信息",
        span_datalog:"采集器序列号:",
        span_name:"别名:",
        span_time:"更新时间:",
        span_firmware:"信号/固件版本:",

        span_order:"命令",
        span_settingdns:"设置域名",
        span_inputsetting:"请输入域名",
        span_upgrade:"升级采集器",
        span_reset:"重启采集器",

        span_setup:"高级设置",
        span_param:"参数",
        span_value:"值",

        btn_setup:"高级设置",
        btn_setting:"设置",
        btn_settingdns:"设置域名",
        btn_upgrade:"升级",
        btn_reset:"重启",
        btn_read:"读取",
        btn_close:"取消",

        methods_version:"检测版本更新?",
        no:"否",
        yes:"是",

        methods_delete:"删除?",
        methods_rereset:"重启?",
        methodes_inputDns:"请输入域名",
        methodes_DNS:"是否设置此域名",
    },
    sotrage:{
        select:"请选择",
        email:"请输入邮箱",
        datalogSn:"请输入采集器序列号",
        deviceSn:"请输入设备序列号",
        search:"搜索",
        sotrage:"储能设置",

        Function_enable:"功能使能",
        Model_number:"机型编号",
        output_power:"输出功率",
        individual_address:"单个地址",
        Set_number:"设置序列号",
        Set_time:"设置时间",
        Set_Multiple_addresses:"多个地址",
    },
    device:{
        select:"请选择",
        email:"请输入邮箱",
        datalogSn:"请输入采集器序列号",
        deviceSn:"请输入设备序列号",
        search:"搜索",

        title:"逆变器设置",
        setting:"设置",
        setting_inverte:"设置逆变器参数",
        read_data_device:"读取逆变器参数:",

        input_start_address:"起始寄存器地址",
        input_ent_address:"终止寄存器地址",
        input_setting_value:"请输入设置值",

        read_data:"读取寄存器数据:",
        input_start_read:"起始寄存器地址",
        input_end_read:"终止寄存器地址",

        btn_address:"设置地址",
        btn_value:"设置参数",
        btn_read:"读取",
        btn_close:"返回",

        selet_data:"选择日期",
    },
    battery:{
        select:"请选择",
        email:"请输入邮箱",
        datalogSn:"请输入采集器序列号",
        deviceSn:"请输入设备序列号",
        search:"搜索",
        

    },
    socket:{
        title:"插座设置",
        setting:"设置",
        span:"设置了多个寄存器地址，值也要设置多个。 设置值中间要用 , 隔开，示例（1，2，3）",
        setting_device:"设置逆变器参数：",
        startaddress:"起始寄存器地址",
        stopaddress:"终止寄存器地址",
        value:"请输入设置值",
        readdata:"读取寄存器数据：",
        setting_address:"设置地址",
        setting_value:"设置参数",
        read:"读取",
        return:"返回",
    },
    agent:{
        close:"取消",
        submit:"提交",
        input_email:"请输入用户邮箱",
        search:"搜索",
        add_agent:"添加代理商",
        import_device:"导入设备",
        edit:"编辑",
       
        add_agent:"添加代理商",
        title:"添加代理商",
        email:"邮箱",
        password:"密码",
        phone:"手机号码",
        company:"公司",
        language:"语言",
        address:"地址",
       
        input_email:"请输入邮箱",
        input_password:"请输入密码",
        input_phone:"请输入手机号",
        input_company:"请选择公司",
        input_language:"请输入语言",
        input_address:"请输入地址",

        relus_email:"请输入有效的电子邮件地址",

        title_edit:"编辑代理商",

        title_device:"上传",
        span:"示例: Excle 表格",
        btn_upload:"上传",

        delete:"删除",
        agent_delete:"删除代理商?",
    },
    ship:{
        starttime:"开始时间",
        endtime:"结束时间",
        deviceSn:"请输入设备序列号",
        select:"请选择公司",
        search:"搜索"

    },



    Firmware:{
        file_name:"请输入文件名字",
        search:"搜索",
        add:"添加",
        delete:"删除",
        title:"固件上传",
        select_folder:"选择文件夹:",
        select_folder_type:"请选择文件夹类型",
        craete_folder:"创建一个新的文件夹",
        folder_path:"文件夹路径：",
        folder_name:"文件名:",
        input_folder_name:"输入文件名称",
        create:"创建文件夹",
        listed_files:"文件列表：",
        delete:"删除",


        import_folder:"导入文件",
        uplad_folder:"上传文件",
        close:"返回",




    },



    Upgrade:{
        starttime:"开始时间",
        endtime:"结束时间",
        deviceSn:"请输入设备序列号",
        type:"请选择类型",
        search:"搜索",

        datalog:"采集器",
        Inverter:"逆变器",
        Control:"控制器",

    },
    datalogs_logs:{
        starttime:"开始时间",
        endtime:"结束时间",
        deviceSn:"请输入设备序列号",
        Version:"请输入版本",
        search:"搜索"
    },
    Logs:{
        starttime:"开始时间",
        endtime:"结束时间",
        deviceSn:"请输入设备序列号",
        type:"请选择类型",
        search:"搜索",


    },
    Special:{
        starttime:"开始时间",
        endtime:"结束时间",

        deviceSn:"请输入设备序列号",
        datalogSn:"请输入采集器序列号",
        type:"请选择类型",
        search:"搜索",
        save:"保存",
        Examples:"示例：(2305210230,2305210139)",
    },
    App:{
        System_Config:"系统配置",
        APP_Version:"App版本发布",
        Android_Version:"安卓版本:",
        IOS_Version:"IOS版本:",
        APP_Type:"App类型:",
        New_Version:"新版本",
        update_content:"更新内容描述:(用“/n”来划分)",
        Whether_update:"是否强制更新：",
        no:"否",
        yes:"是",
        submit:"提交",
        projectType:"项目类型",
        VersionType:"版本类型",
    },
    Tips:{
        app_submit:"确定修改信息?",
        firmware_delete:"确认删除?",
        firmware_deleteCDN:"删除CDN中的文件?",

        yes:"是",
        no:"否",

    },
    global:{
        DataDetails:"数据详情",
        device:"逆变器设置",
        device_data:"设置逆变器参数:",
        read_data:"读取逆变器参数",

        storage:"储能设置",
        storage_data:"设置储能参数",
        storage_read:"读取储能参数",
        battery:"电池设置",
        battery_data:"设置电池参数",

        socket:"插座设置",
        socket_data:"设置插座参数",


        Read_Write:"读/写数据",
        select:"请选择",
        value:"值",
        password:"密码设置",
        enter:"输入密码",
        number:"序列号",
        enternumber:"输入序列号或选择读取序列号",
        resetdatalog:"重启采集器",
        reset:"重启",
        // 电池设置项说明

        read:"读取",
        setting:"设置",
        read_battery_data:"读取电池数据",


        bms_sanke_Li: {  
            time: "BMS时间",  
            serialNumber: "BMS序列号",  
            batterySerialNumber: "电池序列号",  
            chargeDischargeLimits: "充放电限制",  
            shieldProtection: "屏蔽保护",  
            thresholdCurrent: "门限电流",  
            cycleCount: "循环次数",  
            modifyProtocol: "修改协议",  
            overallOvervoltageAlarm: "总体过压告警",  
            overallOvervoltageProtection: "总体过压保护",  
            overallOvervoltageProtectionRecover: "总体过压保护恢复",  
            overallOvervoltageProtectionDelay: "总体过压保护延时",  
            singleCellOvervoltageAlarm: "单体过压告警",  
            singleCellOvervoltageProtection: "单体过压保护",  
            singleCellOvervoltageProtectionRecover: "单体过压保护恢复",  
            singleCellOvervoltageProtectionDelay: "单体过压保护延时",  
            overallOverdischargeAlarm: "总体过放告警",  
            overallOverdischargeProtection: "总体过放保护",  
            overallOverdischargeProtectionRecover: "总体过放保护恢复",  
            overallOverdischargeProtectionDelay: "总体过放保护延时",  
            singleCellOverdischargeAlarm: "单体过放告警",  
            singleCellOverdischargeProtection: "单体过放保护",  
            singleCellOverdischargeProtectionRecover: "单体过放保护恢复",  
            singleCellOverdischargeProtectionDelay: "单体过放保护延时",  
            chargeOvercurrentAlarm: "充电过流告警",  
            chargeOvercurrentProtection: "充电过流保护",  
            chargeOvercurrentProtectionDelay: "充电过流保护延时",  
            chargeOvercurrentSecondProtection: "充电过流二级保护",  
            chargeOvercurrentSecondProtectionDelay: "充电过流二级保护延时",  
            dischargeOvercurrentAlarm: "放电过流告警",  
            dischargeOvercurrentProtection: "放电过流保护",  
            dischargeOvercurrentProtectionDelay: "放电过流保护延时",  
            dischargeOvercurrentSecondProtection: "放电过流二级保护",  
            dischargeOvercurrentSecondProtectionDelay: "放电过流二级保护延时",  
            shortCircuitProtectionCurrent: "短路保护电流",  
            shortCircuitProtectionDelay: "短路保护延时",  
            highTemperatureChargingAlarm: "充电高温告警",  
            highTemperatureChargingProtection: "充电高温保护",  
            highTemperatureChargingProtectionRecover: "充电过高保护恢复",  
            highTemperatureDischargingAlarm: "放电高温告警",  
            highTemperatureDischargingProtection: "放电高温保护",  
            highTemperatureDischargingProtectionRecover: "放电高温保护恢复",  
            lowTemperatureChargingAlarm: "充电低温告警",  
            lowTemperatureChargingProtection: "充电低温保护",  
            lowTemperatureChargingProtectionRecover: "充电过低保护恢复",  
            lowTemperatureDischargingAlarm: "放电低温告警",  
            lowTemperatureDischargingProtection: "放电低温保护",  
            lowTemperatureDischargingProtectionRecover: "放电低温保护恢复",  
            powerTubeHighTemperatureAlarm: "功率管高温告警",  
            powerTubeHighTemperatureProtection: "功率管高温保护",  
            powerTubeHighTemperatureProtectionRecover: "功率管高温保护恢复",  
            environmentHighTemperatureAlarm: "环境高温告警",  
            environmentHighTemperatureProtection: "环境高温保护",  
            environmentHighTemperatureProtectionRecover: "环境高温保护恢复",  
            environmentLowTemperatureAlarm: "环境低温告警",  
            environmentLowTemperatureProtection: "环境低温保护",  
            environmentLowTemperatureProtectionRecover: "环境高低保护恢复",  
            balancingStartVoltage: "均衡开启电压",  
            balancingStartVoltageDifference: "均衡开启压差",  
            fullChargeVoltage: "充满判断电压",  
            fullChargeCurrent: "充满判断电流",  
            singleCellLowVoltageSleep: "单体低压休眠电压",  
            singleCellLowVoltageSleepDelay: "单体低压休眠延时",  
            standbySleepDelay: "待机休眠延时",  
            lowBatteryAlarmValue: "低电量告警值",  
            overchargeProtectionCapacityRatioRelease: "过充保护容量比解除",  
            heatingFilmStartTemperature: "加热膜开启温度",  
            heatingFilmStopTemperature: "加热膜关闭温度"  
          },  

          TouchSetting:"透传下发",

    },

    btn:{
        setting_address:"设置地址",
        close:"返回",
        submit:"提交",
        Setting_parameters:"设置参数",
        Reading:"读取",
        search:"搜索",
        add:"添加",
        import:"导入",
        download:"下载示例",
        edit:"编辑",
        login:"登录",
        export:"导出",
        agent:"代理商",
        menu:"菜单",
        limits:"保存分配",
        AddZ:"添加主菜单",

    },
    form:{
        addShip:"添加出货设备",
        editShip:"编辑出货设备",
        import:"导入设备信息",
        shipSN:"出货序列号",
        agent:"代理商",
        networkCall:"是否执行配网回调",
        warrantyPeriod:"质保时间",
        shiptime:"出货时间",
        shiptype:"出货类型",
        classify:"分类",
        file:"将文件拖到此处或者点击上传",



        edit_plant:"编辑场景",
        input_station:"请输入场景名字",
        lable_StationName:"场景名字",
        lable_StationType:"场景类型",
        input_StationName:"请输入场景名字",

        edit_plant_plant:"编辑电站",
        input_station_plant:"请输入电站名字",
        lable_StationName_plant:"电站名字",
        lable_StationType_plant:"电站类型",
        input_StationName_plant:"请输入电站名字",
    },
    placeholder:{
        PshipSN:"输入出货序列号",
        Pagent:"输入代理商编码",
        PnetworkCall:"确认是否回调",
        PwarrantyPeriod:"确认质保时间",
        Pshiptime:"确认出货时间",
        Pshiptype:"确认出货类型",
        Pshiptype2:"确认出货类型",

        Pclassify:"确认分类",
    },
    limits:{
        AddRoles:"添加角色",
        nametip:"请输入角色名字",
        remark:"请输入备注",
        EditRoles:"编辑角色",
        RolesAgent:"角色绑定的代理商",
        RoleName:"角色名字:",
        Remark:"备注",
    },
    Menu:{
        AddC:"添加菜单",
        EditC:"修改菜单",
        MenuName:"菜单名字",
        MenuNameTip:"请输入菜单名称",
        url:"地址",
        urlTip:"请输入地址",
        MenuType:"菜单类型",
        MenuTypeTip:"请选择菜单类型",
        Remark:"备注",
        RemarkTip:"请输入备注信息",
        tip:"请输入密码",
        tip2:"请再次输入密码",
        tip3:"两次输入密码不一致!"


    },
    info:{
        Basic_Information:"基本信息",
        User_name:"用户名称",
        Company:"公司",
        Adress:"地址",
        Phone:"手机号",
        Change_Password:"修改密码",
        New_Password:"新密码",
        Old_Password:"旧密码",
        Confirm_Password:"确认密码",
        Update_Acknowledge:"确认修改",
        tip1:"请输入密码",
        tip2:"请再次输入密码",
        tip3:"两次输入密码不一致！",
        tip4:"操作不可逆请确认您的信息",
        tip5:"请输入旧密码",
    },

}